body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scrollbar-width: none;
  background-color: #fff;
  height: 100%;
}

html {
  height: 100%;
  box-sizing: border-box;
}

*,
*::after,
*::before {
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

button {
  cursor: pointer;
  border: none;
  outline: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  color: inherit;
  background-color: transparent;
}

.carousel .control-dots {
  position: absolute;
}

/* Скрывает полосу прокрутки для всех элементов */
::-webkit-scrollbar {
  display: none;
}

.container {
  background-color: white;
  max-width: 768px;
  margin: 0 auto;
}

.input-field::placeholder {
  color: #aeb7c8;
}

.modal-overlay {
  color: red;
  background-color: red;
}

.slide-in {
  color: white;
  font-size: 20px;
  text-align: center;
  animation: slide-in 1s forwards;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
  appearance: none;
}

@keyframes slide-in {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}
